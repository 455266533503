.otpkeypadcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  padding: 10px 20px 0px 20px;


}

.verifyedicon {
  width: 50px;
  height: 16px;
}

.poweredby_text {
  font-size: 10px;
  color: var(--DARK_BLUE);
  font-weight: 900;
  margin: 3px 5px;
}


.headerText {
  font-size: 18px;
  font-weight: 900;
  color: #1e3269;
}

.otpinput {
  margin: 20px 0px 30px 0;
}

.otpinputbox {
  border: 2px solid #cccbd1;
  width: 23px;
  height: 23px;
  margin: 0 3px;
  border-radius: 5px;
}


.gridcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px 10px 0px 10px;
  gap: 10px;
}

.griditem {
  cursor: pointer;
  width: 55px !important;
  height: 55px;
  border: 1px solid #cccbd1;
  color: #909090;
  font-weight: 600;
  padding: 20px;
  font-size: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.lastNumberBox {
  grid-column: 3 / 3;
  border: 0;
  font-size: 13px;
}

.zeroNumberBox {
  grid-column: 3 / 2;
}

.linkText {
  font-size: 12px !important;
  font-weight: 900;
  color: #ffc000 !important;
  text-align: center;
  margin-top: 0px;
  cursor: pointer;
  margin-bottom: 0rem;
}

.infoText {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 900;
  text-align: center;
}

.infoContainer {
  margin: 5px 0;
}