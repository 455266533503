.duoheader,
input.form-control.pin {
  margin-left: auto;
  display: block;
  margin-right: auto;
}

ul.texticon,
ul.ulnavicon {
  display: flex;
  list-style-type: none;
}

.cardtypeui {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.virtualSummary {
  position: absolute;
  top: 20%;
  left: 50.5%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-family: Roboto, sans-serif;
  font-weight: bold;
  z-index: 10;
  width: 100%;
}

.virtualcardsum {
  position: relative;
}

.App,
.formfirst,
.homesummery,
.homesummery1,
.ilnetext-home,
.ilnetext1-home,
.item,
.pinerror.termserror,
.register-button,
button.nextbutton,
p.termcondition {
  text-align: center;
}

.resendOtp,
a.hyperlink,
button.forgototppin,
input.forgotpin,
p.forgotpin {
  text-decoration: underline;
}

.homesummery1 {
  background-color: #bfbfbf;
  padding: 12px 0;
  font-size: 15px;
  color: #000;
  font-weight: 700;
  margin-bottom: .2rem;
}

.HeaderBg,
.HeaderBgverify,
.custom-checkbox input[type=checkbox]:checked+.checkmark {
  background-color: #000;
}

img.duolog,
img.duosublogotop {
  max-width: 100%
}

.duoheader {
  width: 63px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.register {
  margin-top: 30px;
  color: #7f7f7f;
  font-size: 13px;
}

span.remaining.timer,
span.textbold {
  color: #000;
  font-weight: 600;
}

button.nextbutton {
  background-color: #ffc000;
  border: none;
  border-radius: 5px;
  padding: 7px 30px;
  font-size: 16px;
  color: #000;
  margin-left: 7px;
  font-weight: 700;
  margin-top: 30px;
}

p.naira,
p.naira-yellow {
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 700;
}

.register-button {
  margin-top: 12px;
}

p.naira {
  background-color: #d9d9d9;
  border-radius: 5px;
  color: #000;
  margin-bottom: .1rem;
}

p.naira-yellow {
  background-color: #ffc000;
  border-radius: 5px;
  color: #000;
  margin-bottom: .2rem;
}

input[type=password],
input[type=tel] {
  background-color: transparent;
  margin-right: 10px !important;
  width: 25px !important;
}

p.duocard,
p.termcondition {
  font-size: 13px;
  color: #000;
}

.nav-tabs .nav-item,
.now-below,
li.creditdetails1.avail,
p.pinverifyheader,
p.remaplist {
  margin-bottom: 0;
}

p.termcondition {
  padding-top: 150px;
}

input[type=password] {
  border-bottom: 1px solid #000;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
}

input[type=tel] {
  border: none;
  border-bottom: 1px solid #000;
}

input.form-control.pin {
  width: 60%
}

.formfirst {
  margin-top: 40px;
}

p.text-label {
  margin-bottom: .2rem;
  margin-top: .3rem;
  color: #000;
  font-weight: 400;
}

p.duocard {
  margin-left: 0;
  margin-bottom: .3rem;
}

.now-below {
  margin-left: 0;
  margin-top: 9px;
  color: #000;
  font-weight: 600;
}

.homesummery,
.ilnetext-home,
.ilnetext1-home,
button.nextbuttonactive,
p.naira-green {
  font-weight: 700;
  color: #000;
}

ul.texticon {
  padding: 7px;
}

li.text-icon.textimg {
  width: 100px;
}

li.text-icon.textimg1 {
  width: 42px;
}

.ilnetext1 {
  width: 10%;
  float: left;
  padding-left: 6px;
}

.ilnetext {
  float: right;
  width: 85%
}

.divparent1 {
  margin-top: 5px;
}

p.naira-green.active3 {
  margin-left: 0;
  margin-right: 0;
}

p.naira-green {
  background-color: #92d050;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: .2rem;
}

p.accessicon,
p.accessicon1,
p.itemend,
p.itemend1,
ul.ulnavicon {
  margin-bottom: 0;
}

.bg-dark {
  background-color: #000 !important;
  padding: 0;
}

button.nextbuttonactive {
  margin-top: 20px;
  background-color: #ffc000;
  border: none;
  border-radius: 10px;
  text-align: center;
  padding: 7px 30px;
  font-size: 16px;
  margin-left: 7px;
}

.iconwithtextop,
hr.hrline {
  margin-top: 0;
}

.homesummery,
.item,
button.lastday {
  background-color: #bfbfbf;
}

ul.ulnavicon {
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

li.linavigation.imgduologo {
  width: 90px;
  margin-right: 13px;
  margin-left: 10px;
}

li.linavigation {
  margin-right: 10px;
  margin-left: 10px;
}

.homesummery {
  padding: 6px 0;
  font-size: 15px;
  margin-bottom: .2rem;
}

.ilnetext1-home {
  width: 30%;
  float: left;
}

.ilnetext-home {
  float: right;
  width: 20%
}

img.duologohome {
  max-width: 100%;
  margin-left: auto;
  display: block;
}

img.duologohome.icontype {
  margin-left: 0;
}

.apexcharts-canvas {
  position: absolute !important;
  user-select: none;
}

.containerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
  color: #000;
}

.item {
  width: 25%;
  height: 68px;
  font-size: 10px;
  border-radius: 5px 0 0 5px;
  margin-right: 5px;
  padding-top: 6px;
  line-height: 13px;
  font-weight: 700;
  position: relative;
}

.item1,
.item2 {
  border-radius: 0;
  margin-right: 5px;
  width: 25%;
  height: 68px;
  text-align: center;
  font-weight: 700;
  line-height: 13px;
  position: relative;
  font-size: 12px;
}

.item1 {
  background-color: #a6a6a6;
  padding-top: 5px;
}

.duoheaderveryfi,
.item2,
.item3 {
  padding-top: 10px;
}

.item2 {
  background-color: #7f7f7f;
  color: #fff;
}

.item3 {
  width: 25%;
  height: 68px;
  background-color: #595959;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  border-radius: 0 5px 5px 0;
  margin-right: 0;
  color: #fff;
  line-height: 13px;
  position: relative;
}

td,
th {
  border: 1px solid #495057;
}

.iconwithtext,
.iconwithtextop {
  margin-left: 4px;
  margin-right: 4px;
  height: 20px;
}

.iconwithtext,
.iconwithtext1,
.iconwithtext2,
.iconwithtextop {
  color: #000;
  font-weight: 700;
}

.chartstatus,
.chartstatusloader,
p.itemend,
p.itemend1 {
  position: absolute;
}

p.itemend {
  font-size: 12px;
  font-weight: 700;
  bottom: 7px;
  left: 0;
  right: 0;
}

p.itemend1 {
  font-size: 12px;
  bottom: 9px;
  left: 0;
  right: 0;
}

.iconwithtext1 {
  float: left;
  width: 15%;
  padding-left: 0;
}

.iconwithtext2 {
  float: right;
  width: 85%;
  font-size: 14px;
}

img.duologicon {
  max-width: 50%
}

p.toptex {
  font-size: 11px;
  margin-left: 2px;
  margin-right: 2px;
}

p.itemend0 {
  font-size: 12px;
  padding-top: 21px;
  font-weight: 700;
}

.HeaderBgverify {
  background-color: #000;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.duoheaderveryfi {
  width: 63px;
  padding-bottom: 10px;
}

.listitem,
.listitemrepayment1 {
  text-align: center;
  width: 33.33%
}

p.pinverifyheader {
  width: 65%;
  color: #fff;
  text-align: center;
  padding-top: 12px;
  font-size: 17px;
}

label.uploadlabel,
label.uploadlabel1 {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.formfirst.pinform {
  margin-top: 38px;
}

label.uploadlabel1 {
  padding-top: 30px;
}

.container-listitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding-left: 5px;
  padding-right: 5px;
}

.listitem {
  color: #000;
  font-weight: 700;
  font-size: 12px;
}

.listitemrepayment,
.listitemrepayment2 {
  text-align: center;
  color: #000;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  width: 33.33%
}

p.accessicon {
  padding-top: 3px;
  padding-left: 3px;
}

.naira-symbol {
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

p.accessicon1 {
  padding-left: 5px;
  margin-right: 3px;
}

.otpfields,
.statuscircle,
img.hidemage {
  margin-left: auto;
  margin-right: auto;
}

.listitemrepayment1 {
  color: #000;
  font-weight: 700;
  font-size: 12px;
}

.registerVirtual {
  margin-top: 0;
  color: #7f7f7f;
  font-size: 12px;
}

hr.hrline {
  color: #000;
  border-top: 1px solid #bfbfbf;
  margin-bottom: .5rem;
}

.repayment-box {
  background-color: #595959;
  color: #fff;
  margin-top: 15px;
  padding: 7px 10px;
  text-align-last: left;
  font-size: 13px;
  font-weight: 400;
}

.repayment-box.boxshadow {
  margin-top: 5px;
}

p.creditdetailsbox {
  font-size: 16px;
  margin-bottom: 5px;
}

p.creditdetails {
  font-size: 16px;
  margin-bottom: 7px;
}

.creditboldyellow {
  font-size: 1rem;
  color: #ffc000;
}

p.creditdetails1 {
  margin-bottom: .06rem;
}

p.creditdetails2 {
  margin-top: .5rem;
  margin-bottom: 0;
  font-size: 16px;
}

form.repaymentform {
  margin-top: 10px;
  padding-left: 7px;
  padding-right: 7px;
}

label.rpaymentpin {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 30px;
}

button.lastday,
td,
th {
  font-size: 11px;
  color: #000;
}

.listitemremap,
button.lastday {
  margin-bottom: 0;
  font-weight: 700;
}

table {
  border-collapse: collapse;
  width: 100%
}

td,
th {
  text-align: left;
  padding: 8px;
}

.chartstatusloader {
  top: 47.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  font-weight: 700;
  display: flex;
}

.chartstatus,
.chartstatusloader,
.listitemremap1,
.nextbuttonpin,
.otpinputs,
.poster .back,
button.lastday,
td,
th {
  text-align: center;
}

th {
  background-color: #fdc002;
  font-weight: 700;
}

td {
  font-weight: 700;
}

button.lastday {
  border: none;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

p.remapdebitnew2,
ul.listremap {
  color: #000;
  margin-bottom: 1.3rem;
}

.p-2.bd-highlight.activemtd1 {
  padding: .1rem !important;
  width: 20%
}

.listitemremap {
  width: 32%;
  color: #000;
  font-size: 14px;
}

input.forgotpin {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 600;
}

p.registerscreen {
  color: #000;
  font-weight: 400;
  font-size: 14px;
}

.listitemremap1 {
  width: 65%;
  color: #fff;
  font-weight: 700;
  background-color: #000;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.container-listitem.Remapdiv {
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
}

ul.listremap {
  list-style-type: decimal;
  font-size: 15px;
  font-weight: 700;
  padding-left: 15px;
}

span.applycast,
span.branchradio {
  padding-left: 10px;
}

p.remapdebitnew2 {
  font-size: 13px;
  font-weight: 400;
  margin-top: 16px;
}

.applyduotext {
  font-size: 13px;
  font-weight: 700;
  margin-top: 20px;
  color: #000;
}

.chartstatus,
.chartstatusloader,
p.applytext {
  font-size: 14px;
  color: #000;
}

.chartstatus {
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  font-weight: 700;
  display: flex;
}

.homecharts {
  height: 255px;
}

.status,
.status2 {
  width: 30%;
  padding-top: 35px;
}

.container.activetext {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.status1 {
  width: 40%
}

.statuscircle {
  height: 40px;
  width: 40px;
  background-color: #ffc000;
  border-radius: 50%;
  margin-top: 5px;
}

.statuscirclegreen {
  height: 40px;
  width: 40px;
  background-color: #92d050;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.statuscircle1,
.statuscircle2 {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.statuscircle1 {
  background-color: #fff;
}

.statuscircle2 {
  background-color: #92d050;
}

.container.remapview {
  margin-top: 16px;
}

p.applytext {
  margin: 13px 10px .5rem;
}

p.remapdebit,
p.remapdebitnew {
  font-weight: 400;
  margin-bottom: .7rem;
  font-size: 13px;
  color: #000;
}

p.remapdebit {
  margin-top: 54px;
}

p.remapdebitnew {
  margin-top: 16px;
}

.nairaduocard {
  background-color: #d9d9d9;
  border: none;
  border-radius: 5px;
  padding: 7px 5px;
  margin-left: 7px;
  font-weight: 700;
  margin-right: 7px;
}

p.applybank {
  font-size: 17px;
  color: #000;
  margin-bottom: 0;
}

p.applybranch {
  font-size: 14px;
  color: #000;
  margin-bottom: .3rem;
}

span.branchradio {
  font-size: 14px;
  color: #000;
  font-weight: 700;
}

form.formapply {
  margin-left: 10px;
  margin-right: 10px;
}

select#exampleFormControlSelect1 {
  background-color: #d9d9d9;
  margin-top: 24px;
  color: #000;
  font-weight: 700;
  font-size: 13px;
}

.poster-container {
  width: 278px;
  border-radius: 15px;
  cursor: pointer;
  perspective: 600;
  position: relative;
  margin: 15px auto;
}

.poster,
.poster .pic,
.toggleeye {
  position: absolute;
}

.poster {
  height: 100%;
  transform-style: preserve-3d;
  transition: 1s ease-in-out;
  width: 100%;
  padding-top: 10px;
}

.flipped,
.poster .back {
  transform: rotateY(180deg);
}

.poster .pic {
  backface-visibility: hidden;
  border-radius: 6px;
  overflow: hidden;
  width: 100%
}

.poster .back {
  color: #0087cc;
}

.nextbuttonpin {
  background-color: #ffc000;
  border: none;
  border-radius: 10px;
  padding: 7px 30px;
  font-size: 16px;
  color: #000;
  margin-left: 7px;
  font-weight: 700;
  margin-top: 35px;
}

.pinerror,
.text-danger {
  font-size: 12px;
  color: red;
  font-weight: 700;
}

.otpinputs,
.pinInput {
  margin-left: auto;
  margin-right: auto;
}

.form-group.groupmargin,
p.registerscreen {
  margin-bottom: .4rem;
}

hr.borderhome {
  margin-top: .8rem;
  margin-bottom: .5rem;
}

.container.homemenues {
  margin-top: 11px;
}

.pinInput {
  display: flex;
  background-color: #d9d9d9;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .105rem .75rem 2.4rem;
  width: 70%
}

span.otpInput {
  padding-right: 15px;
}

.toggleeye {
  padding-top: 7px;
  right: 76px;
}

p.forgotpin {
  color: #000;
  font-weight: 700;
  padding-top: 25px;
  font-size: 14px;
  margin-bottom: 0;
}

.pinerror {
  padding-top: 3px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  font-size: 14px;
  font-weight: 700;
  padding: .2rem 0;
  color: #fff;
  width: 100%;
  font-style: italic;
}

li.nav-item {
  width: 50%
}

.nav-tabs {
  border-bottom: none;
  background-color: #000;
  border-radius: 5px;
  padding: 2px;
  margin-top: 15px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000;
  background-color: #fdc002;
  border-color: transparent !important;
  border-radius: 4px;
  width: 100%
}

.blackButton,
.whiteButton {
  text-align: center;
  border: none;
  margin-bottom: 0;
  font-size: 12px;
  padding: 0;
  color: #000;
  width: 100%;
  font-weight: 600;
  height: 50px;
  display: flex;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.blackButton {
  background-color: #fdc002;
  align-items: center;
  justify-content: center;
}

.whiteButton {
  background-color: #bfbfbf;
  align-items: center;
  justify-content: center;
}

.boxcard,
.boxcard1 {
  border-radius: 10px;
  padding: 10px;
  margin-top: 45px;
}

.boxcard {
  background-image: linear-gradient(to bottom right, #000, #aeaeb2);
  height: 165px;
}

.boxcard1 {
  background-image: linear-gradient(to bottom right, #555, #555);
  height: 170px;
}

.cvvblack {
  height: 32px;
  width: 100%;
  background-color: #000;
}

.cvvwhite {
  height: 32px;
  background-color: #fff;
  margin-top: 10px;
  margin-left: 6%;
  margin-right: 6%
}

.righticon,
button.forgototppin,
img.duologohomev,
p.month {
  margin-left: auto;
}

p.cvvnum {
  float: right;
  padding-right: 10px;
  padding-top: 1.4%;
  color: #000;
  font-size: 17px;
}

.logocard {
  width: 60px;
}

.boxtoplogo,
.cardname,
.creditminimum li {
  display: flex;
}

.boxtext {
  color: #fff;
  padding-left: 36px;
  padding-top: 13px;
}

.nextbuttonpinRey,
.nextbuttonpinreg {
  border: none;
  padding: 7px 30px;
  margin-left: 7px;
  text-align: center;
}

p.cardnumber {
  font-size: 17px;
  margin-bottom: .5rem;
}

.boxtext1 {
  width: 80%
}

p.namecards {
  font-size: 14px;
  margin-bottom: 0;
}

p.activationsStatus,
p.month,
p.valid {
  margin-bottom: .5rem;
}

p.cvvtextdetails {
  text-align: center;
  font-size: 16px;
  color: #000;
  padding-top: 5px;
  font-weight: 600;
}

.cardinformation {
  display: flex;
  font-size: 14px;
}

p.month {
  margin-right: auto;
  padding-left: 40px;
}

.righticon.bottomicon {
  padding-right: 9%
}

input.form-control.pinreypmnt {
  width: 100%;
  margin-left: auto;
  display: block;
  margin-right: auto;
  background-color: #d9d9d9;
  height: 32px;
  border: none;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.nextbuttonpinRey {
  background-color: #ffc000;
  border-radius: 10px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-top: 15px;
}

.registertop {
  margin-top: 12px;
  color: #7f7f7f;
  font-size: 14px;
}

span.checkbox-label {
  padding-left: 25px;
}

p.approvedtext {
  margin-bottom: .2rem;
  font-size: 14px;
  font-weight: 700;
}

.custom-checkbox {
  position: relative;
  display: flex;
  margin-right: 10px;
}

.custom-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50px;
  border: 2px solid #fdc002;
}

.custom-checkbox:hover input[type=checkbox]+.checkmark {
  background-color: #ccc;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input[type=checkbox]:checked+.checkmark:after {
  display: block;
  left: 5px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

p.termscondition {
  font-size: 14px;
  margin-bottom: .1rem;
  margin-top: .5rem;
  color: #000;
}

ul.ulist {
  list-style-type: disc;
  font-size: 13px;
  font-weight: 700;
  color: #000;
  margin-bottom: .5rem;
  padding-left: 36px;
}

span.checkbox-label2 {
  padding-left: 25px;
  color: #000;
  font-size: 13px;
  font-weight: 400;
}

.nextbuttonpinreg {
  background-color: #ffc000;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-top: 0;
}

.select-popup select,
select {
  width: 200px;
  font-size: 16px;
  border: none;
}

.applyduotextreg {
  font-size: 13px;
  font-weight: 700;
  margin-top: 10px;
  color: #000;
  padding-left: 10%;
  padding-right: 10%
}

.form-group.optionbranch {
  margin-left: 4%;
  margin-right: 4%
}

.registercredit {
  margin-top: 10px;
  color: #7f7f7f;
  font-size: 14px;
}

.select-popup select {
  height: 50px;
  border-radius: 4px;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
}

.select {
  position: relative;
  margin-bottom: 5px;
  margin-top: 15px;
}

.loader,
.select-arrow {
  position: absolute;
  top: 50%
}

select {
  padding: 8px 24px 8px 12px;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 4px;
}

.searchinputselect,
input.searchinput {
  width: 100%;
  background-color: #ddd;
  border: none;
}

p.errormsgpin1 {
  font-size: 12px;
  margin-bottom: 0;
  color: red;
  margin-top: 5px;
  font-weight: 600;
}

.searchinputselect {
  height: auto;
  padding-left: 5px;
  padding-right: 48px;
  font-size: 13px;
  font-weight: 700;
  resize: none;
  display: flex;
  white-space: pre-wrap;
}

.select-arrow {
  right: 18px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 8px solid #333;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  cursor: pointer;
}

.selectdiv {
  margin-left: 6%;
  margin-right: 6%
}

input.searchinput {
  font-size: 15px;
  height: 35px;
  border-radius: 6px;
  padding: 5px;
}

p.selectpreferred {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

ul.listbranch {
  list-style: none;
  font-size: 14px;
  padding-left: 6px;
  margin-top: 12px;
}

ul.listbranch li {
  border-bottom: 1px solid #ddd;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 700;
}

button {
  background-color: initial;
}

.ReactModal__Content.ReactModal__Content--after-open {
  top: 77px;
  inset: 83px 0px !important;
}

.applyduotextregfirst {
  font-size: 13px;
  font-weight: 700;
  margin-top: 10px;
  color: #000;
}

.termshere {
  padding-left: 1.4rem;
}

p.termscondition1 {
  font-size: 14px;
  margin-bottom: .1rem;
  color: #000;
  margin-top: 0;
}

.resendOtp {
  border: none;
  font-size: 14px;
  font-weight: 700;
}

.resendpinotp {
  margin-top: 25px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.otpinputs:focus {
  outline: 0;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid #fdc002;
  font-weight: 700;
}

input.otpinputs {
  background-color: transparent;
  margin-right: 10px !important;
  width: 25px !important;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

p.errormsgpin {
  font-size: 12px;
  margin-bottom: 0;
  color: red;
  margin-top: 3px;
  font-weight: 600;
}

.modal-header,
.repaymenthide {
  display: none;
}

p.resuccess {
  font-size: 14px;
  font-weight: 600;
  padding-top: 24px;
}

.d-flex.flex-row.mb-3.transparent {
  margin-bottom: .4rem !important;
}

a.hyperlink {
  color: #000;
  font-weight: 700;
}

p.historytext {
  margin-bottom: 3px;
  margin-top: 0;
  color: #595959;
  font-weight: 600;
}

.resend {
  padding-top: 12px;
}

img.duosublogotopmain {
  max-width: 85%
}

.loader {
  left: 50%;
  transform: translate(-50%, -50%);
}

ul.creditlistdetails {
  list-style: none;
  padding-left: .7rem;
}

.credit {
  list-style: none;
  padding: 0 0 0 12px;
  line-height: 11px;
  margin-bottom: 0;
}

.credit li {
  display: flex;
  margin-bottom: 10px;
}

.credit li span:first-child {
  margin-right: 20px;
}

.creditbold {
  font-size: 13px;
}

ul.credit.creditlistflow {
  padding-left: 0;
}

.flowlist {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}

.flowlist1 {
  display: flex;
  font-size: 16px;
}

li.duepayment {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.creditminimum {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

li.duepayment1 {
  padding-left: 11px;
  color: #ffc000;
}

.creditminimum li span:first-child {
  margin-right: 10px;
}

.container-listitem.remap-text {
  padding-left: 0;
  padding-right: 0;
}

p.applytextduo {
  font-size: 14px;
  color: #000;
  margin-bottom: .5rem;
  margin-top: 13px;
}

.nairaduocardapply {
  background-color: #d9d9d9;
  border: none;
  border-radius: 5px;
  padding: 7px 5px;
  font-weight: 700;
}

ul.ulist li {
  line-height: 21px;
}

p.small-text {
  margin-bottom: 4px;
}

button.forgototppin {
  background-color: transparent;
  border: none;
  font-size: 13px;
  font-weight: 600;
  color: #ffc000;
  display: block;
  margin-right: auto;
}

.register-button.pinotp {
  margin-top: 0;
}

p.setuppin {
  color: #000;
}

img.duologohomev {
  max-width: 85%;
  display: block;
  margin-right: auto;
}

.modal-dialog {
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 70%;
  border-radius: 41px;
}

.navi,
button.btn.btn-secondary {
  margin-right: auto;
  margin-left: auto;
}

.modal-footer {
  border-top: none;
  padding: 0;
}

button.btn.btn-secondary {
  background-color: transparent;
  border: none;
  margin-top: 0;
  padding-top: 0;
  color: #00baf2;
  font-weight: 600;
}

.modal-body {
  padding: 0;
  font-size: 14px;
}

p.modaltext {
  padding-top: 10px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  color: #000;
  font-weight: 600;
}

textarea.searchinputselect {
  display: block;
  margin: 0 auto;
  height: 45px;
  line-height: 14px;
  padding-left: 10px;
}

.modal-content {
  border-radius: 10px;
}

p.errorpin {
  background-color: #aaa;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: .1rem !important;
}

.navi {
  display: block;
}

.listitemrepayment.cardtypelist {
  width: 45%
}

textarea {
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea::placeholder {
  text-align: center;
  line-height: 2.5rem;
}

.duoheaderveryfihome {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
}

p.RecentRepayment {
  margin-top: .7rem;
  margin-bottom: .2rem;
  font-size: 13px;
  font-weight: 600;
  color: #000;
}

@media only screen and (min-width:320px) and (max-width:360px) {

  .nav-tabs .nav-link,
  p.errorpin {
    font-size: 12px;
  }

  td {
    font-size: 9px;
    padding: 5px 2px;
  }

  .blackButton,
  .whiteButton {
    font-size: 10px;
  }

  .col-md-6.transactioncolumns {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width:361px) and (max-width:392px) {
  td {
    font-size: 10px;
    padding: 5px 2px;
  }
}

h1 {
  color: rgb(0, 128, 111);
}

/* .loaderlogo {
  display: block;
  position: absolute;
  width: 30%;
  height: 15px;
  top: 50%;
  left: 35%;
  transform: translateX(-50%);
  animation: rotateAnimation;
  animation-iteration-count: infinite;
  animation-duration: 2.85s;
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(55deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.loader1 {
  animation-delay: 0.12s;
  background-color: #FAA300;
}

.loader2 {
  animation-delay: 0.22s;
  background-color: #FFBD00;
}

.loader3 {
  animation-delay: 0.32s;
  background-color: #F0CD00;
}

.loader4 {
  animation-delay: 0.42s;
  background-color: #FFDE50;
}

.loader5 {
  animation-delay: 0.52s;
  background-color: #FFEA94;
}

.loaderduologo {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 43%;
  left: 30%;
  right: 30%;
} */

/* .loaderlogo {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 1.25em);
  border-radius: 1.25em;
  transform-origin: 1.25em 1.25em;
  animation: rotateAnimation;
  animation-iteration-count: infinite;
  animation-duration: 1.85s;
  top: 50%;
}

@keyframes rotateAnimation {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.loader1 {
  animation-delay: 0.12s;
  background-color: #FAA300;
}

.loader2 {
  animation-delay: 0.22s;
  background-color: #FFBD00;
}

.loader3 {
  animation-delay: 0.32s;
  background-color: #F0CD00;
}

.loader4 {
  animation-delay: 0.42s;
  background-color: #FFDE50;
}

.loader5 {
  animation-delay: 0.52s;
  background-color: #FFEA94;
}

.loaderduologo {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 40%;
  left: 30%;
  right: 30%;
} */

.loaderClass {
  position: relative;
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 250px;
}

.loaderCircle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 12px solid #dcd7d7;
  border-top: 12px solid #FFBD00;
  border-radius: 50%;
  animation: SpinLoader 2.5s linear infinite;
}

.loaderClass img {
  position: relative;
  z-index: 1;
  /* Ensure the image stays above the loader circle */
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@keyframes SpinLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

img.duologcircle {
  width: 68%;
  /* text-align: center; */
  left: 17%;
}